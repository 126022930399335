const routes = [
    {
        path: `/me`,
        name: "me",
        component: () => import("../pages/profile/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Profile", label: "Profile" }],
        },
    },
];
export default routes;
