import axios from "axios";
import Cookies from "js-cookie"; // Import js-cookie package
import router from "../../router";
// import { authHeader } from "../helpers/authservice/auth-header";
import { inject } from "vue";

const app = inject("app");

let Api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    withCredentials: true,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        // "Content-Type": "multipart/form-data",
    },
});

Api.interceptors.response.use(
    (response) => {
        // Jika status 204, redirect ke halaman 404
        if (response.status === 500) {
            window.location.href = "/error";
        }
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 403) {
            // Redirect to 403 page
            //   router.push({ name: 'forbidden' });
            window.location.href = "/#/access";
        }
        if (error.response && error.response.status === 400) {
            // Redirect to 403 page
            //   router.push({ name: 'forbidden' });
            // window.location.href = '/#/access';
            console.log(app);
            console.log(error);
            // app.config.globalProperties.$toast.add({
            //     severity: 'error',
            //     summary: 'Failed',
            //     detail: 'Load data Barang',
            //     life: 3000,
            // });
        }
        if (error.response && error.response.status === 404) {
            // Redirect to 403 page
            //   router.push({ name: 'forbidden' });
            // window.location.href = '/#/404';
        }
        if (error.response && error.response.status === 401) {
            // Redirect to 403 page
            //   router.push({ name: 'forbidden' });
            if (!window.location.href.includes("login")) {
                window.location.href = "/#/expired";
            }
        }
        return Promise.reject(error);
    },
);
Api.defaults.withCredentials = true;

const user = [];
// TODO: Implement Auhtentication with cookie based
async function Login(user) {
    try {
        const response = await Api.post("/auth/login", user); // Replace with your endpoint to login
        const token = response.data.data[0].token; // The server should respond with a JWT token
        setAuthToken(token); // Set the token in the cookie
        // app.authorization.user = response.data.data
        user = response.data.data[0];
        router.push({ name: "dashboard" }); // Redirect to the dashboard page
        return true;
    } catch (error) {
        console.error("Error logging in:", error);
        return false;
    }
}

async function Logout() {
    try {
        await Api.post("/auth/logout"); // Replace with your endpoint to logout
        removeAuthToken(); // Remove the token from the cookie
        router.push({ name: "login" }); // Redirect to the login page
        return true;
    } catch (error) {
        console.error("Error logging out:", error);
        return false;
    }
}
// Function to set the JWT token in a cookie
function setAuthToken(token) {
    Cookies.set("jwt-local", token, { expires: 1 }); // You can adjust the expiration time as needed
    Api.defaults.headers.common["token"] = token;
}
function getAuthToken() {
    const token = Cookies.get("jwt-local"); // You can adjust the expiration time as needed
    if (!token) return null;
    return token;
}
function setUser(user) {
    Cookies.set("user", user, {
        expires: 1,
        maxAge: 3600000, // 1 hour (time in milliseconds)
        httpOnly: false,
        secure: true, // Set to 'true' if using HTTPS
        sameSite: "lax", // Adjust according to your requirements
    }); // You can adjust the expiration time as needed
    Api.defaults.headers.common["user"] = user;
}
function getAuthUser() {
    const user = Cookies.get("user"); // You can adjust the expiration time as needed
    return user;
}

// Function to remove the JWT token from the cookie
function removeAuthToken() {
    Cookies.remove("jwt-local");
    Cookies.remove("jwt");
    delete Api.defaults.headers.common["token"];
}

async function checkAuthentication() {
    try {
        const response = await Api.get("/auth/check-auth"); // Replace with your endpoint to check the authentication status on the server
        if (response.status === 200) {
            router.push({ name: "dashboard" }); // Redirect to the dashboard page
        }
        if (response.status === 401) {
            router.push({ name: "login" }); // Redirect to the dashboard page
        }
        return response.data; // The server should respond with a boolean indicating the authentication status
    } catch (error) {
        router.push({ name: "login" }); // Redirect to the login page
        console.error("Error checking authentication:", error);
        return false;
    }
}

// var user = JSON.parse(localStorage.getItem('user'));
// var user = getAuthToken()
// if (user) Api.defaults.headers.common['token'] = user.token;

export { Api, setAuthToken, getAuthToken, removeAuthToken, checkAuthentication, Login, Logout, setUser, getAuthUser, user };
