import moment from "moment-timezone";
import apiSetting from "../service/api/setting/setting";
const Helpers = {
    install: function (app, options) {
        options = options || {};
        options.requestOnNotify = options.requestOnNotify || true;
        // login function
        const formatDate = function (value) {
            if (value != null) {
                return moment(value).tz("Asia/Makassar").format("DD-MM-YYYY");
            }
            return null;
        };

        const formatDateYmd = function (value) {
            if (value != null) {
                return moment(value).tz("Asia/Makassar").format("YYYY-MM-DD");
            }
            return null;
        };

        const formatTime = function (value) {
            if (value != null) {
                let date = moment(value).toDate();
                return [date.getHours().toString().padStart(2, "0"), (date.getMinutes() + 1).toString().padStart(2, "0")].join(":");
            }
            return null;
        };

        const formatCurrency = function (value) {
            value = value != null ? value : 0;
            return parseFloat(value, 2).toLocaleString("id-ID", { style: "currency", currency: "IDR" });
        };
        const DateUTC = async function (value = null) {
            const serverTime = await apiSetting.now({ date: value });
            return new Date(serverTime.data.date);
        };

        const formatNumber = function (value) {
            value = value || 0;
            return parseFloat(value, 2).toLocaleString("id-ID");
        };

        const getLabel = function (status) {
            switch (status) {
                case "A":
                    return "approve";
                case "R":
                    return "reject";
                case "D":
                    return "draft";
                case "O":
                    return "open";
                case "S":
                    return "menunggu persetujuan";
                case "V":
                    return "delivery process";
                case "P":
                    return "partial";
                case "U":
                    return "upload";
                case "C":
                    return "close";
                case "X":
                    return "cancel";
                case "Q":
                    return "telah direvisi";
                default:
                    return "draft";
            }
        };

        app.config.globalProperties.$h = {
            formatDate,
            formatDateYmd,
            formatTime,
            formatCurrency,
            formatNumber,
            getLabel,
            DateUTC,
        };
        // app.provide('h', app.config.globalProperties.$h)
    },
};

// export function useHelpers() {
//     return inject('h')
// }

export default Helpers;
