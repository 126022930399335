import { inject } from "vue";

import apiAuthentication from "../service/api/authentication/userlogin";
import { Logout, removeAuthToken } from "../service/api/Api";

// Plugin
const Authorization = {
    install: function (app, options) {
        options = options || {};
        options.requestOnNotify = options.requestOnNotify || true;

        app.auth = {};
        app.config.globalProperties.$auth = {};

        app.auth.user = {
            isAuthenticated: false,
            name: null,
            id_pegawai: null,
            username: null,
            jabatan: null,
            departemen: null,
            kantor: null,
            loginterbaru: null,
            loginterakhir: null,
            roles: [],
        };
        app.config.globalProperties.$auth.user = app.auth.user;

        // login function
        const login = async function (form) {
            app.config.globalProperties.$auth.user = {};
            console.log(form);
            const res = await apiAuthentication.login(form);
            // if (res.data.status == 200) {
            app.auth.user = res.data.data;

            app.config.globalProperties.$auth.user = app.auth.user;

            localStorage.user = JSON.stringify(app.auth.user);
            return res;
        };
        app.auth.login = login;
        app.config.globalProperties.$auth.login = login;

        const logout = async function () {
            localStorage.removeItem("user");
            removeAuthToken();
            await Logout();
            app.auth.user = {
                isAuthenticated: false,
                nama: null,
                username: null,
            };
            app.config.globalProperties.$auth.user = app.auth.user;
        };
        app.auth.logout = logout;
        app.config.globalProperties.$auth.logout = logout;

        const id = function () {
            const user = localStorage.getItem("user");
            return JSON.parse(user)?.id_pegawai;
        };
        app.auth.id = id;
        app.config.globalProperties.$auth.id = id;

        app.provide("auth", app.config.globalProperties.$auth);
    },
};

export function useNativeNotifications() {
    return inject("auth");
}

export default Authorization;
