import { createRouter, createWebHashHistory } from "vue-router";
import Master from "./routes/master";
import Transaction from "./routes/transaction";
import Finance from "./routes/finance";
import Setting from "./routes/setting";
import Profile from "./routes/profile";
import Warehouse from "./routes/warehouse";
import Report from "./routes/report";
import App from "./App.vue";
import { Api } from "./service/api/Api";

// import { getAuthToken } from './service/api/Api';
// TODO: Implement authentication Cookie based

const routes = [
    {
        path: "/dashboard",
        name: "app",
        component: App,
        children: [
            {
                path: "/",
                name: "dashboard",
                exact: true,
                component: () => import("./pages/Dashboard.vue"),
                meta: {
                    breadcrumb: [{ parent: "Favorites", label: "Dashboard" }],
                },
            },
            ...Finance,
            ...Master,
            ...Setting,
            ...Transaction,
            ...Warehouse,
            ...Report,
            ...Profile,
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./pages/Login.vue"),
        meta: {
            requiresNoAuth: true,
        },
    },
    {
        path: "/error",
        name: "error",
        component: () => import("./pages/Error.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "notfound",
        component: () => import("./pages/NotFound.vue"),
    },
    {
        path: "/404",
        name: "404",
        component: () => import("./pages/NotFound.vue"),
    },
    {
        path: "/access",
        name: "access",
        component: () => import("./pages/Access.vue"),
    },
    {
        path: "/expired",
        name: "expired",
        component: () => import("./pages/Expired.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    // Check if the route requires authentication
    if (to.meta.requiresAuth) {
        try {
            // Make an API request to your backend to check if the user is authenticated
            const response = await Api.get("/auth/check-auth");
            const user = response.data; // Assuming your backend sends back user information if authenticated
            console.log(user, "ini user");
            if (user) {
                // If the user is authenticated, check for role-based authorization
                if (to.meta.role && !user.data.hak_akses.includes(to.meta.role)) {
                    // If the user doesn't have the required role, redirect to an access denied page
                    // next();
                    next("/access");
                } else if (to.meta.requiresNoAuth) {
                    // If the user is already logged in, redirect to the home page
                    next("/");
                } else {
                    // // Attach the authenticated user to the route's meta field
                    // to.meta.authenticatedUser = user;
                    // If the user has the required role, allow navigation to proceed
                    next();
                }
            } else {
                // If the user is not authenticated, redirect to the login page
                next("/login");
            }
        } catch (error) {
            // Handle any errors that may occur during the API request
            console.error("Authentication error:", error);
            next("/login");
        }
    } else {
        // If the route doesn't require authentication, allow navigation to proceed
        next();
    }
});

// router.beforeEach((to, from, next) => {
//     // TODO: Implement authentication Cookie based
//     let user = getAuthToken();

//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (!user) {
//             next('/login');
//         }
//         else {
//             if (to.meta.role) {
//                 if (!user.hak_akses.includes(to.meta.role)) {
//                     next('/access');
//                 }
//             }
//         }
//     }
//     next();
// });

// router.beforeEach((to, from, next) => {
//     if(to.meta.role) {
//         let user = getAuthToken()
//         // if(!user) {
//         //     next('/login');
//         // } else {
//             if(to.meta.role) {
//                 if(!user.hak_akses.includes(to.meta.role)) {
//                     next('/access');
//                 }
//             }
//             next();
//         // }
//     }
//     next();
// });
// ...

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        console.log("start", Date.now());
    }
    next();
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    console.log("done", Date.now());
});

export default router;
