const routes = [
    // START Erza

    //END Erza

    // START Alwi
    // {
    //     path: "/picking/",
    //     name: "picking.index",
    //     component: () => import("../pages/warehouse/picking/list.vue"),
    //     meta: {
    //         breadcrumb: [{ label: "Picking", to: "/picking" }],
    //     },
    // },
    // {
    //     path: '/picking/salesorder/',
    //     name: 'picking.salesorder.index',
    //     component: () => import('../pages/warehouse/picking/order.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { label: "Picking", to: "/picking" },
    //             { label: "Sales Order", to: "/picking" },
    //         ],
    //     }
    // },
    // {
    //     path: "/picking/create",
    //     name: "picking.create",
    //     component: () => import("../pages/warehouse/picking/form.vue"),
    //     meta: {
    //         breadcrumb: [{ label: "Picking", to: "/picking" }, { label: "Create" }],
    //     },
    // },
    // {
    //     path: "/picking/:id/edit",
    //     name: "picking.edit",
    //     component: () => import("../pages/warehouse/picking/form.vue"),
    //     meta: {
    //         breadcrumb: [{ label: "Purchase Order", to: "/picking" }, { label: "Edit" }],
    //     },
    // },
    // delivery
    {
        path: "/delivery/order",
        name: "do.index",
        component: () => import("../pages/warehouse/delivery/order/list.vue"),
        meta: {
            breadcrumb: [{ label: "Delivery Order", to: "/delivery/order" }],
            role: 'ROLE_DELIVERYORDER',
        },
    },
    {
        path: "/delivery/order/create",
        name: "do.create",
        component: () => import("../pages/warehouse/delivery/order/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Delivery Order", to: "/delivery/order" },
                { label: "Create", to: "/delivery/order/create" },
            ],
            role: 'ROLE_DELIVERYORDER',
        },
    },
    {
        path: "/delivery/order/:id/edit",
        name: "do.edit",
        component: () => import("../pages/warehouse/delivery/order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Delivery Order", to: "/delivery/order" }, { label: "Edit" }],
            role: 'ROLE_DELIVERYORDER',
        },
    },
    {
        path: "/delivery/order/:id/cetak",
        name: "do.cetak",
        component: () => import("../pages/warehouse/delivery/order/cetak.vue"),
        meta: {
            breadcrumb: [{ label: "Delivery Order", to: "/delivery/order" }, { label: "Cetak" }],
            role: 'ROLE_DELIVERYORDER',
        },
    },
    //Delivery receive
    {
        path: "/delivery/receive",
        name: "do-receive.index",
        component: () => import("../pages/warehouse/delivery/receive/list.vue"),
        meta: {
            breadcrumb: [{ label: "Delivery Receive", to: "/delivery/receive" }],
            role: 'ROLE_DELIVERYRECEIVE',
        },
    },
    {
        path: "/delivery/receive/create",
        name: "do-receive.create",
        component: () => import("../pages/warehouse/delivery/receive/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Delivery Receive", to: "/delivery/receive" },
                { label: "Create", to: "/delivery/receive/create" },
            ],
            role: 'ROLE_DELIVERYRECEIVE',
        },
    },
    {
        path: "/delivery/receive/:id/edit",
        name: "do-receive.edit",
        component: () => import("../pages/warehouse/delivery/receive/form.vue"),
        meta: {
            breadcrumb: [{ label: "Delivery Receive", to: "/delivery/receive" }, { label: "Edit" }],
            role: 'ROLE_DELIVERYRECEIVE',
        },
    },

    // peminjaman Barang
    {
        path: "/peminjaman/alat",
        name: "borrow.index",
        component: () => import("../pages/warehouse/borrow/list.vue"),
        meta: {
            breadcrumb: [{ label: "Peminjaman Alat", to: "/peminjaman/alat" }],
            role: "ROLE_PEMINJAMANALAT",
        },
    },
    {
        path: "/peminjaman/alat/create",
        name: "borrow.create",
        component: () => import("../pages/warehouse/borrow/form.vue"),
        meta: {
            breadcrumb: [{ label: "Peminjaman Alat", to: "/peminjaman/alat" }, { label: "Create" }],
            role: "ROLE_PEMINJAMANALAT",
        },
    },
    {
        path: "/peminjaman/alat/:id/edit",
        name: "borrow.edit",
        component: () => import("../pages/warehouse/borrow/form.vue"),
        meta: {
            breadcrumb: [{ label: "Peminjaman Alat", to: "/peminjaman/alat" }, { label: "Edit" }],
            role: "ROLE_PEMINJAMANALAT",
        },
    },

    // penerimaanbarang
    {
        path: "/penerimaanbarang",
        name: "penerimaanbarang.index",
        component: () => import("../pages/warehouse/penerimaan-barang/list.vue"),
        meta: {
            breadcrumb: [{ label: "Penerimaan Barang", to: "/penerimaanbarang" }],
            role: "ROLE_PENERIMAANBARANG",
        },
    },
    {
        path: "/penerimaanbarang/create",
        name: "penerimaanbarang.create",
        component: () => import("../pages/warehouse/penerimaan-barang/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Penerimaan Barang", to: "/penerimaanbarang" },
                { label: "Create", to: "/penerimaanbarang/create" },
            ],
            role: "ROLE_PENERIMAANBARANG",
        },
    },
    {
        path: "/penerimaanbarang/:id/edit",
        name: "penerimaanbarang.edit",
        component: () => import("../pages/warehouse/penerimaan-barang/form.vue"),
        meta: {
            breadcrumb: [{ label: "Penerimaan Barang", to: "/penerimaanbarang" }, { label: "Edit" }],
            role: "ROLE_PENERIMAANBARANG",
        },
    },
    {
        path: "/usage",
        name: "usage.index",
        component: () => import("../pages/warehouse/usage/list.vue"),
        meta: {
            breadcrumb: [{ label: "Biaya", to: "/usage" }],
            role: "ROLE_PEMAKAIANBARANG",
        },
    },
    {
        path: "/usage/create",
        name: "usage.create",
        component: () => import("../pages/warehouse/usage/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Biaya", to: "/usage" },
                { label: "Create", to: "/usage/create" },
            ],
            role: "ROLE_PEMAKAIANBARANG",
        },
    },
    {
        path: "/usage/:id/edit",
        name: "usage.edit",
        component: () => import("../pages/warehouse/usage/form.vue"),
        meta: {
            breadcrumb: [{ label: "Biaya", to: "/usage" }, { label: "Edit" }],
            role: "ROLE_PEMAKAIANBARANG",
        },
        //END Alwi
    },
    {
        path: "/stok-opname",
        name: "stokopname.index",
        component: () => import("../pages/warehouse/stokopname/list.vue"),
        meta: {
            breadcrumb: [{ label: "Stok Opname", to: "/stok-opname" }],
            role: "ROLE_STOKOPNAME",
        },
    },
    {
        path: "/stok-opname/create",
        name: "stokopname.create",
        component: () => import("../pages/warehouse/stokopname/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Stok Opname", to: "/stok-opname" },
                { label: "Create", to: "/stok-opname/create" },
            ],
            role: "ROLE_STOKOPNAME",
        },
    },
    {
        path: "/stok-opname/:id/edit",
        name: "stokopname.edit",
        component: () => import("../pages/warehouse/stokopname/form.vue"),
        meta: {
            breadcrumb: [{ label: "Stok Opname", to: "/stok-opname" }, { label: "Edit" }],
            role: "ROLE_STOKOPNAME",
        },
    },
    // Asset Barang
    {
        path: "/asset-barang",
        name: "asset-barang.index",
        component: () => import("../pages/warehouse/asset-barang/list.vue"),
        meta: {
            breadcrumb: [{ label: "Asset Barang", to: "/asset-barang" }],
            role: "ROLE_ASSETBARANG",
        },
    },
    // {
    //     path: '/asset-barang/create',
    //     name: 'asset-barang.create',
    //     component: () => import('../pages/warehouse/asset-barang/form.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { label: "Asset Barang", to: "/asset-barang" },
    //             { label: "Create", to: "/asset-barang/create" }
    //         ],
    //         role: 'ROLE_STOKOPNAME'
    //     }
    // },
    // {
    //     path: '/asset-barang/:id/edit',
    //     name: 'asset-barang.edit',
    //     component: () => import('../pages/warehouse/asset-barang/form.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { label: "Asset Barang", to: "/asset-barang" },
    //             { label: "Edit" }
    //         ],
    //         role: 'ROLE_STOKOPNAME'
    //     }
    // },
    {
        path: "/pengembalian/alat",
        name: "pengembalianalat.index",
        component: () => import("../pages/warehouse/pengembalianalat/list.vue"),
        meta: {
            breadcrumb: [{ label: "Pengembalian", to: "/pengembalian/alat" }],
            role: "ROLE_PENGEMBALIANALAT",
        },
    },
    {
        path: "/pengembalian/alat/create",
        name: "pengembalianalat.create",
        component: () => import("../pages/warehouse/pengembalianalat/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Pengembalian", to: "/pengembalian/alat" },
                { label: "Create", to: "/pengembalian/alat/create" },
            ],
            role: "ROLE_PENGEMBALIANALAT",
        },
    },
    {
        path: "/pengembalian/alat/:id/edit",
        name: "pengembalianalat.edit",
        component: () => import("../pages/warehouse/pengembalianalat/form.vue"),
        meta: {
            breadcrumb: [{ label: "Pengembalian", to: "/pengembalian/alat" }, { label: "Edit" }],
            role: "ROLE_PENGEMBALIANALAT",
        },
    },
];
export default routes;
