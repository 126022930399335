<template>
    <span :class="containerClass" v-bind="ptm('root')">
        <component v-if="$slots.icon" :is="$slots.icon" class="p-tag-icon" v-bind="ptm('icon')" />
        <span v-else-if="icon" :class="iconClass" v-bind="ptm('icon')"></span>
        <slot>
            <span class="p-tag-value" v-bind="ptm('value')">{{ value }}</span>
        </slot>
    </span>
</template>

<script>
import BaseComponent from "primevue/basecomponent";

export default {
    name: "Tag",
    extends: BaseComponent,
    props: {
        value: null,
        severity: null,
        rounded: Boolean,
        icon: String,
    },
    computed: {
        containerClass() {
            return [
                "p-tag p-component",
                {
                    "p-tag-reject": this.severity === "reject",
                    "p-tag-approve": this.severity === "approve",
                    "p-tag-draft": this.severity === "draft",
                    "p-tag-open": this.severity === "open",
                    "p-tag-menunggu persetujuan": this.severity === "menunggu persetujuan",
                    "p-tag-posting": this.severity === "posting",
                    "p-tag-partial": this.severity === "partial",
                    "p-tag-delivery process": this.severity === "delivery process",
                    "p-tag-close": this.severity === "close",
                    "p-tag-cancel": this.severity === "cancel",
                    "p-tag-rounded": this.rounded,
                },
            ];
        },
        iconClass() {
            return ["p-tag-icon", this.icon];
        },
    },
};
</script>

<style scoped>
.p-tag {
    background: #d49341;
    color: #f8fafc;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 3px;
}

.p-tag.p-tag-approve {
    background-color: #71d560;
    /* Warna Kuning */
    color: #ffffff;
}
.p-tag.p-tag-draft {
    background-color: #ffc107;
    /* Warna Kuning */
    color: #212529;
}
.p-tag.p-tag-reject {
    background-color: #ff9d9d;
    /* Warna Kuning */
    color: rgb(168, 18, 18);
}

.p-tag.p-tag-open {
    background-color: #4caf50;
    /* Warna Hijau */
    color: #ffffff;
}
.p-tag.p-tag-menunggu.persetujuan {
    background-color: #517eff;
    /* Warna Biru */
    color: #ffffff;
}

.p-tag.p-tag-posting {
    background-color: #e91e63;
    /* Warna Merah Muda */
    color: #ffffff;
}

.p-tag.p-tag-partial {
    background-color: #9c27b0;
    /* Warna Ungu */
    color: #ffffff;
}

.p-tag.p-tag-delivery.process {
    background-color: #03a9f4;
    /* Warna Biru Terang */
    color: #ffffff;
    /* Warna Teks Putih */
}

.p-tag.p-tag-delivery-receipt {
    background-color: #2979ff;
    /* Warna Biru Lebih Terang */
    color: #ffffff;
    /* Warna Teks Putih */
}

.p-tag.p-tag-invoice-created {
    background-color: #ff9800;
    /* Warna Oranye */
    color: #ffffff;
}

.p-tag.p-tag-close {
    background-color: #ff5722;
    /* Warna Merah */
    color: #ffffff;
}

.p-tag.p-tag-cancel {
    background-color: #d32f2f;
    /* Warna Merah Gelap */
    color: #ffffff;
}

.p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
}

.p-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.p-tag-icon,
.p-tag-value,
.p-tag-icon.pi {
    line-height: 1.5;
}

.p-tag.p-tag-rounded {
    border-radius: 10rem;
}
</style>
