<!-- MyExport.vue -->
<template>
    <div>
        <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportToExcel" />
    </div>
</template>

<script>
import * as XLSX from "xlsx-js-style";

export default {
    props: {
        value: {
            type: Array,
            required: true,
        },
        selectedColumns: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: "exported_data",
        },
    },
    methods: {
        exportToExcel() {
            const data = this.value;
            const selectedColumns = this.selectedColumns;

            // Add a title row
            const titleRow = [this.title];
            const titleCellStyle = {
                font: { bold: true, sz: 20 },
                alignment: {
                    vertical: "center",
                    horizontal: "center",
                },
            };

            // Add a separator row
            const separatorRow = Array(selectedColumns.length + 1).fill("");

            // Add a header row
            const headerRow = ["No", ...selectedColumns.map((column) => column.header)];

            // Add data rows
            const dataWithIndex = data.map((item, index) => [index + 1, ...selectedColumns.map((column) => this.formatData(item, column))]);

            const ws = XLSX.utils.aoa_to_sheet([titleRow, separatorRow, headerRow, ...dataWithIndex]);

            // Set styles for the title row
            ws["!rows"] = [{ hpt: 20, hidden: false }, ...Array(data.length + 3).fill({ hpt: 15, hidden: false })];
            ws["!cols"] = [{ width: 9 }, ...Array(selectedColumns.length).fill({ width: 20 })];

            // Set up merges for the title row
            ws["!merges"] = [{ s: { c: 0, r: 0 }, e: { c: selectedColumns.length, r: 0 } }];

            // Kasih style judul di A1
            ws["A1"] = Object.assign({}, ws["A1"], { s: titleCellStyle });

            // Ambil semua range
            const all_range = XLSX.utils.decode_range(ws["!ref"]);

            // Set the starting cell coordinates
            const startCell = { row: 2, col: 1 }; // Adjust as needed

            // Create a style object with the desired border style
            const cellStyleWithBorder = {
                border: {
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                },
            };
            console.log(all_range, "Ini all range");

            // Apply the border style to cells from the starting cell to the last cell
            for (let rowIndex = startCell.row; rowIndex <= all_range.e.r; rowIndex++) {
                for (let colIndex = startCell.col - 1; colIndex <= all_range.e.c; colIndex++) {
                    const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                    if (cellRef == "A3") {
                        // Kasih tebal header mulai dari A3
                        const a1_range = XLSX.utils.encode_range({ s: { c: 0, r: 2 }, e: { c: headerRow.length - 1, r: 2 } });
                        const range = XLSX.utils.decode_range(`${a1_range}`);
                        for (let row = range.s.r; row <= range.e.r; row++) {
                            for (let col = range.s.c; col <= range.e.c; col++) {
                                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                                ws[cellAddress].s = { ...cellStyleWithBorder, font: { bold: true } };
                            }
                        }
                        break;
                    } else {
                        if (!ws[cellRef]) {
                            ws[cellRef] = { t: "s", v: "" };
                        }
                        ws[cellRef].s = cellStyleWithBorder;
                    }
                }
            }

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            XLSX.writeFile(wb, `${this.title}_${new Date().getTime()}.xlsx`);
        },
        formatData(item, column) {
            switch (column.type) {
                case "date":
                    return this.$h.formatDate(item[column.field]);

                case "currency":
                    return this.$h.formatNumber(parseInt(item[column.field]));

                case "number":
                    return this.$h.formatNumber(item[column.field]);

                default:
                    return item[column.field];
            }
        },
    },
};
</script>

<style scoped>
/* Your component's styles here */
</style>
