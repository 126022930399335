const selectAllTextDirective = {
    mounted(el) {
        const isInputNumber = el.classList.contains("p-inputnumber");
        const isInputText = el.classList.contains("p-inputtext");
        if (isInputNumber) {
            const inputNumber = el.querySelector("input");
            inputNumber.addEventListener("focus", () => {
                inputNumber.select();
            });
        }

        if (isInputText) {
            el.addEventListener("focus", () => {
                el.select();
            });
        }
    },
};

export default selectAllTextDirective;
