const routes = [
    // START Erza

    //END Erza

    // START Alwi

    //Work Order
    {
        path: "/work/order",
        name: "wo",
        component: () => import("../pages/marketing/work/order/list.vue"),
        meta: {
            breadcrumb: [{ label: "Work Order", to: "/work/order" }],
            role: "ROLE_WORKORDER",
        },
    },
    {
        path: "/work/order/create",
        name: "wo.create",
        component: () => import("../pages/marketing/work/order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Work Order", to: "/work/order" }, { label: "Create" }],
            role: "ROLE_WORKORDER",
        },
    },
    {
        path: "/work/order/:id/edit",
        name: "wo.edit",
        component: () => import("../pages/marketing/work/order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Work Order", to: "/work/order" }, { label: "Edit" }],
            role: "ROLE_WORKORDER",
        },
    },
    {
        path: "/work/order/:id/cetak",
        name: "wo.cetak",
        component: () => import("../pages/marketing/work/order/cetak.vue"),
        meta: {
            breadcrumb: [{ label: "Work Order", to: "/work/order" }, { label: "Cetak" }],
            role: "ROLE_WORKORDER",
        },
    },
    //Purchase Request
    {
        path: "/purchase/request",
        name: "purchase-request",
        component: () => import("../pages/marketing/purchase/request/list.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Request", to: "/purchase/request" }],
            role: "ROLE_PURCHASEREQUESTION",
        },
    },
    {
        path: "/purchase/request/create",
        name: "purchase-request.create",
        component: () => import("../pages/marketing/purchase/request/form.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Request", to: "/purchase/request" }, { label: "Create" }],
            role: "ROLE_PURCHASEREQUESTION",
        },
    },
    {
        path: "/purchase/request/:id/edit",
        name: "purchase-request.edit",
        component: () => import("../pages/marketing/purchase/request/form.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Request", to: "/purchase/request" }, { label: "Edit" }],
            role: "ROLE_PURCHASEREQUESTION",
        },
    },
    {
        path: "/purchase/request/:id/cetak",
        name: "purchase-request.cetak",
        component: () => import("../pages/marketing/purchase/request/cetak.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Request", to: "/purchase/request" }, { label: "Cetak" }],
            role: "ROLE_PURCHASEREQUESTION",
        },
    },
    //Purchase Order
    {
        path: "/purchase/order",
        name: "po",
        component: () => import("../pages/marketing/purchase/order/list.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Order", to: "/purchase/order" }],
            role: "ROLE_PURCHASEORDER",
        },
    },
    {
        path: "/purchase/order/create",
        name: "po.create",
        component: () => import("../pages/marketing/purchase/order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Order", to: "/purchase/order" }, { label: "Create" }],
            role: "ROLE_PURCHASEORDER",
        },
    },
    {
        path: "/purchase/order/:id/edit",
        name: "po.edit",
        component: () => import("../pages/marketing/purchase/order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Order", to: "/purchase/order" }, { label: "Edit" }],
            role: "ROLE_PURCHASEORDER",
        },
    },
    {
        path: "/purchase/order/:id/cetak",
        name: "po.cetak",
        component: () => import("../pages/marketing/purchase/order/cetak.vue"),
        meta: {
            breadcrumb: [{ label: "Purchase Order", to: "/purchase/order" }, { label: "Cetak" }],
            role: "ROLE_PURCHASEORDER",
        },
    },
    //Realisasi Work Order
    {
        path: "/realisasi-work-order",
        name: "realisasi-wo",
        component: () => import("../pages/marketing/realisasi-work-order/list.vue"),
        meta: {
            breadcrumb: [{ label: "Realisasi Work Order", to: "/realisasi-work-order" }],
            role: "ROLE_WORKORDER",
        },
    },
    {
        path: "/realisasi-work-order/create",
        name: "realisasi-wo.create",
        component: () => import("../pages/marketing/realisasi-work-order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Realisasi Work Order", to: "/realisasi-work-order" }, { label: "Create" }],
            role: "ROLE_WORKORDER",
        },
    },
    {
        path: "/realisasi-work-order/:id/edit",
        name: "realisasi-wo.edit",
        component: () => import("../pages/marketing/realisasi-work-order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Realisasi Work Order", to: "/realisasi-work-order" }, { label: "Edit" }],
            role: "ROLE_WORKORDER",
        },
    },
    {
        path: "/realisasi-work-order/:id/cetak",
        name: "realisasi-wo.cetak",
        component: () => import("../pages/marketing/realisasi-work-order/cetak.vue"),
        meta: {
            breadcrumb: [{ label: "Realisasi Work Order", to: "/realisasi-work-order" }, { label: "Cetak" }],
            role: "ROLE_WORKORDER",
        },
    },

    //END Alwi
    {
        path: "/penawaran",
        name: "penawaran",
        component: () => import("../pages/marketing/penawaran/list.vue"),
        meta: {
            breadcrumb: [{ label: "Penawaran", to: "/penawaran" }],
            role: "ROLE_PENAWARAN",
        },
    },

    {
        path: "/penawaran/create",
        name: "penawaran.create",
        component: () => import("../pages/marketing/penawaran/form.vue"),
        meta: {
            breadcrumb: [{ label: "Penawaran", to: "/penawaran" }, { label: "Create" }],
            role: "ROLE_PENAWARAN",
        },
    },
    {
        path: "/penawaran/:id/edit",
        name: "penawaran.edit",
        component: () => import("../pages/marketing/penawaran/form.vue"),
        meta: {
            breadcrumb: [{ label: "Penawaran", to: "/penawaran" }, { label: "Edit" }],
            role: "ROLE_PENAWARAN",
        },
    },
    {
        path: "/penawaran/:id/cetak",
        name: "penawaran.cetak",
        component: () => import("../pages/marketing/penawaran/cetak.vue"),
        meta: {
            breadcrumb: [{ label: "Penawaran", to: "/penawaran" }, { label: "Cetak" }],
            role: "ROLE_PENAWARAN",
        },
    },
    {
        path: "/permintaan-penawaran",
        name: "permintaan-penawaran",
        component: () => import("../pages/marketing/permintaan-penawaran/list.vue"),
        meta: {
            breadcrumb: [{ label: "Permintaan Penawaran", to: "/permintan-penawaran" }],
            role: "ROLE_PERMINTAANPENAWARAN",
        },
    },
    {
        path: "/permintaan-penawaran/checklist",
        name: "checklist-permintaan-penawaran",
        component: () => import("../pages/marketing/permintaan-penawaran/checklist.vue"),
        meta: {
            breadcrumb: [{ label: "Permintaan Penawaran", to: "/permintan-penawaran" }, { label: "Checklist", to: "/permintan-penawaran/checklist" }],
            role: "ROLE_PERMINTAANPENAWARAN",
        },
    },
    {
        path: "/permintaan-penawaran/create",
        name: "permintaan-penawaran.create",
        component: () => import("../pages/marketing/permintaan-penawaran/form.vue"),
        meta: {
            breadcrumb: [{ label: "Permintaan Penawaran", to: "/permintaan-penawaran" }, { label: "Create" }],
            role: "ROLE_PERMINTAANPENAWARAN",
        },
    },
    {
        path: "/permintaan-penawaran/:id/edit",
        name: "permintaan-penawaran.edit",
        component: () => import("../pages/marketing/permintaan-penawaran/form.vue"),
        meta: {
            breadcrumb: [{ label: "Permintaan Penawaran", to: "/permintaan-penawaran" }, { label: "Edit" }],
            role: "ROLE_PERMINTAANPENAWARAN",
        },
    },
    {
        path: "/sales-order",
        name: "sales-order",
        component: () => import("../pages/marketing/sales-order/list.vue"),
        meta: {
            breadcrumb: [{ label: "Sales Order", to: "/sales-order" }],
            role: `ROLE_SALESORDER`
        },
    },
    {
        path: "/sales-order/create",
        name: "sales-order.create",
        component: () => import("../pages/marketing/sales-order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Sales Order", to: "/sales-order" }, { label: "Create" }],
            role: 'ROLE_SALESORDER'
        },
    },
    {
        path: "/sales-order/:id/edit",
        name: "sales-order.edit",
        component: () => import("../pages/marketing/sales-order/form.vue"),
        meta: {
            breadcrumb: [{ label: "Sales Order", to: "/sales-order" }, { label: "Edit" }],
            role: 'ROLE_SALESORDER'
        },
    },
];
export default routes;
