const routes = [
    // START Erza
    {
        path: "/master/brand",
        name: "brand",
        component: () => import("../pages/master/brand/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Brand" }],
            role: "ROLE_BRAND",
        },
    },
    {
        path: "/master/satuan",
        name: "satuan",
        component: () => import("../pages/master/satuan/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Satuan" }],
            role: "ROLE_SATUAN",
        },
    },
    {
        path: "/master/barang/kategori",
        name: "kategori-barang",
        component: () => import("../pages/master/produk/kategori/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Kategori Barang" }],
            role: "ROLE_KATEGORIBARANG",
        },
    },
    {
        path: "/master/barang",
        name: "barang",
        component: () => import("../pages/master/produk/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Barang" }],
            role: "ROLE_BARANG",
        },
    },
    //END Erza

    // START Alwi
    {
        path: "/master/kategorikontak",
        name: "kategorikontak",
        component: () => import("../pages/master/kontak/kategori/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Kategori Kontak" }],
            role: "ROLE_KATEGORIKONTAK",
        },
    },
    {
        path: "/master/kontak/bank",
        name: "masterbank",
        component: () => import("../pages/master/kontak/bank/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Kontak Bank" }],
            role: "ROLE_KONTAKBANK",
        },
    },
    {
        path: "/master/kontak",
        name: "kontak",
        component: () => import("../pages/master/kontak/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Kontak" }],
            role: "ROLE_KONTAK",
        },
    },
    {
        path: "/master/kantor",
        name: "masterkantor",
        component: () => import("../pages/master/kantor/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Kantor" }],
            role: "ROLE_KANTOR",
        },
    },
    {
        path: "/master/gudang",
        name: "mastergudang",
        component: () => import("../pages/master/gudang/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Gudang" }],
            role: "ROLE_GUDANG",
        },
    },
    {
        path: "/master/jabatan",
        name: "masterjabatan",
        component: () => import("../pages/master/jabatan/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Jabatan" }],
            role: "ROLE_JABATAN",
        },
    },
    {
        path: "/master/departemen",
        name: "masterdepartemen",
        component: () => import("../pages/master/departemen/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Departemen" }],
            role: "ROLE_DEPARTEMEN",
        },
    },
    {
        path: "/master/tipe-harilibur",
        name: "masterhariliburtipe",
        component: () => import("../pages/master/harilibur/tipe/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Tipe Hari Libur" }],
            role: "ROLE_TIPEHARILIBUR",
        },
    },
    {
        path: "/master/menu",
        name: "mastermenu",
        component: () => import("../pages/master/menu/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Menu" }],
            role: "ROLE_AKUN",
        },
    },
    // HRD
    {
        path: "/hrd/pegawai",
        name: "masterpegawai",
        component: () => import("../pages/master/pegawai/list.vue"),
        meta: {
            breadcrumb: [{ parent: "HRD", label: "Pegawai" }],
            role: "ROLE_PEGAWAI",
        },
    },

    {
        path: "/master/user",
        name: "masteruser",
        component: () => import("../pages/master/user/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "User" }],
            role: "ROLE_USERMANAGEMENT",
        },
    },
    {
        path: "/master/hak-akses/:usernamenya",
        name: "masterhakakses",
        component: () => import("../pages/master/hakakses/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Hak Akses" }],
            role: "ROLE_USERMANAGEMENT",
        },
    },
    {
        path: "/master/kategori-asset-barang",
        name: "kategori-asset-barang",
        component: () => import("../pages/master/kategori-asset-barang/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Kategori Asset Barang" }],
            role: 'ROLE_ASSETKATEGORI'
        },
    },
    {
        path: "/master/judul-penawaran",
        name: "judul-penawaran",
        component: () => import("../pages/master/judul-penawaran/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Master", label: "Judul Penawaran" }],
            role: "ROLE_JUDULPENAWARAN",
        },
    },
    //END Alwi
];
export default routes;
