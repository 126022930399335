import { Api } from "../Api";
import qs from "qs";

export default {
    now(date = null) {
        return Api.get("/date?" + qs.stringify(date));
    },
    index() {
        return Api.get("/setting/");
    },
    store(form) {
        return Api.post("/setting/", form);
    },
    update(id, form) {
        return Api.patch("/setting/" + id, form);
    },
    show(id) {
        return Api.get("/setting/" + id);
    },
    destroy(id) {
        return Api.delete("/setting/" + id);
    },
};
