import { Api } from "../Api";

export default {
    index(usernamenya) {
        return Api.get(`/menu${usernamenya ? `?usernamenya=${usernamenya}` : ""}`);
    },
    store(form) {
        return Api.post("/menu/", form);
    },
    update(id, form) {
        return Api.patch("/menu/" + id, form);
    },
    show(id) {
        return Api.get("/menu/" + id);
    },
    delete(id) {
        return Api.delete("/menu/" + id);
    },
    getMenuTree() {
        return Api.get("/menu/tree");
    },
};
