const routes = [
    // akun
    {
        path: "/master/akun",
        name: "akun",
        component: () => import("../pages/master/akun/list.vue"),
        meta: {
            breadcrumb: [{ label: "Master Akun", to: "/master/akun" }],
            role: 'ROLE_AKUN',
        },
    },

    // ap
    {
        path: `/finance/ap`,
        name: "ap.index",
        component: () => import("../pages/finance/ap/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "AP" }],
            role: "ROLE_AP",
        },
    },
    {
        path: `/finance/ap/create`,
        name: "ap.create",
        component: () => import("../pages/finance/ap/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "AP", to: "/finance/ap" }, { label: "Create" }],
            role: "ROLE_AP",
        },
    },
    {
        path: `/finance/ap/:id/edit`,
        name: "ap.edit",
        component: () => import("../pages/finance/ap/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "AP", to: "/finance/ap" }, { label: "Edit" }],
            role: "ROLE_AP",
        },
    },
    {
        path: `/finance/ap/:id/print`,
        name: "ap.print",
        component: () => import("../pages/finance/ap/cetak.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "AP", to: "/finance/ap" }, { label: "Journal" }],
            role: "ROLE_PAYMENTAP",
        },
    },
    // proposal ap
    // {
    //     path: `/finance/ap/proposal`,
    //     name: 'proposalap.index',
    //     component: () => import('../pages/finance/proposal-ap/list.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { parent: 'Finance', label: 'Proposal AP', to: "/finance/ap/proposal" },
    //         ],
    //         role: 'ROLE_AP',
    //     }
    // },
    // {
    //     path: `/finance/ap/proposal/create`,
    //     name: 'proposalap.create',
    //     component: () => import('../pages/finance/proposal-ap/form.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { parent: 'Finance', label: 'Proposal AP', to: "/finance/ap/proposal" },
    //             { label: 'Create' },
    //         ],
    //     }
    // },
    // {
    //     path: `/finance/ap/proposal/:id/edit`,
    //     name: 'proposalap.edit',
    //     component: () => import('../pages/finance/proposal-ap/form.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { parent: 'Finance', label: 'Proposal AP', to: "/finance/ap/proposal" },
    //             { label: "Edit" }
    //         ],
    //     }
    // },
    // Tanda Terima Invoice
    {
        path: `/tanda-terima-invoice`,
        name: "tanda-terima-invoice.index",
        component: () => import("../pages/marketing/tanda-terima-invoice/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Tanda Terima Invoice", to: "/tanda-terima-invoice" }],
            role: 'ROLE_TANDATERIMAINVOICE',
        },
    },
    {
        path: `/tanda-terima-invoice/create`,
        name: "tanda-terima-invoice.create",
        component: () => import("../pages/marketing/tanda-terima-invoice/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Tanda Terima Invoice", to: "/tanda-terima-invoice" }, { label: "Create" }],
            role: 'ROLE_TANDATERIMAINVOICE',
        },
    },
    {
        path: `/tanda-terima-invoice/:id/edit`,
        name: "tanda-terima-invoice.edit",
        component: () => import("../pages/marketing/tanda-terima-invoice/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Tanda Terima Invoice", to: "/tanda-terima-invoice" }, { label: "Edit" }],
            role: 'ROLE_TANDATERIMAINVOICE',
        },
    },
    {
        path: `/tanda-terima-invoice/:id/cetak`,
        name: "tanda-terima-invoice.cetak",
        component: () => import("../pages/marketing/tanda-terima-invoice/cetak.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Tanda Terima Invoice", to: "/tanda-terima-invoice" }, { label: "Cetak" }],
            role: 'ROLE_TANDATERIMAINVOICE',
        },
    },
    // payment ap
    {
        path: `/finance/ap/payment`,
        name: "paymentap.index",
        component: () => import("../pages/finance/payment-ap/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AP", to: "/finance/ap/payment" }],
            role: "ROLE_PAYMENTAP",
        },
    },
    {
        path: `/finance/ap/payment/create`,
        name: "paymentap.create",
        component: () => import("../pages/finance/payment-ap/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AP", to: "/finance/ap/payment" }, { label: "Create" }],
            role: "ROLE_PAYMENTAP",
        },
    },
    {
        path: `/finance/ap/payment/:id/edit`,
        name: "paymentap.edit",
        component: () => import("../pages/finance/payment-ap/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AP", to: "/finance/ap/payment" }, { label: "Edit" }],
            role: "ROLE_PAYMENTAP",
        },
    },
    {
        path: `/finance/ap/payment/:id/print`,
        name: "paymentap.print",
        component: () => import("../pages/finance/payment-ap/cetak.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AP", to: "/finance/ap/payment" }, { label: "Journal" }],
            role: "ROLE_PAYMENTAP",
        },
    },
    // payment ar
    {
        path: `/finance/ar/payment`,
        name: "paymentar.index",
        component: () => import("../pages/finance/payment-ar/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AR", to: "/finance/ar/payment" }],
            role: "ROLE_PAYMENTAR",
        },
    },
    {
        path: `/finance/ar/payment/create`,
        name: "paymentar.create",
        component: () => import("../pages/finance/payment-ar/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AR", to: "/finance/ar/payment" }, { label: "Create" }],
            role: "ROLE_PAYMENTAR",
        },
    },
    {
        path: `/finance/ar/payment/:id/edit`,
        name: "paymentar.edit",
        component: () => import("../pages/finance/payment-ar/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AR", to: "/finance/ar/payment" }, { label: "Edit" }],
            role: "ROLE_PAYMENTAR",
        },
    },
    {
        path: `/finance/ar/payment/:id/print`,
        name: "paymentar.print",
        component: () => import("../pages/finance/payment-ar/cetak.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Payment AR", to: "/finance/ar/payment" }, { label: "Journal" }],
            role: "ROLE_PAYMENTAR",
        },
    },
    // invoice
    {
        path: `/invoice`,
        name: "invoice.index",
        component: () => import("../pages/finance/invoice/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Invoice" }],
        },
    },
    {
        path: `/invoice/create`,
        name: "invoice.create",
        component: () => import("../pages/finance/invoice/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Invoice", to: "/invoice" }, { label: "Create" }],
            role: "ROLE_INVOICE",
        },
    },
    {
        path: `/invoice/:id/edit`,
        name: "invoice.edit",
        component: () => import("../pages/finance/invoice/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Invoice", to: "/invoice" }, { label: "Edit" }],
            role: "ROLE_INVOICE",
        },
    },
    {
        path: `/invoice/:id/jurnal`,
        name: "invoice.jurnal",
        component: () => import("../pages/finance/invoice/jurnal.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Invoice", to: "/invoice" }, { label: "Jurnal" }],
            role: "ROLE_INVOICE",
        },
    },
    {
        path: `/invoice/:id/cetak`,
        name: "invoice.cetak",
        component: () => import("../pages/finance/invoice/cetak.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Invoice", to: "/invoice" }, { label: "Cetak" }],
            role: "ROLE_INVOICE",
        },
    },
    {
        path: `/invoice/:id/cetak-kwitansi`,
        name: "invoice.cetak_kwitansi",
        component: () => import("../pages/finance/invoice/cetak_kwitansi.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Invoice", to: "/invoice" }, { label: "Cetak Kwitansi" }],
            role: "ROLE_INVOICE",
        },
    },
    //saldo awal ap
    {
        path: `/saldo-awal-ap`,
        name: "saldo-awal-ap.index",
        component: () => import("../pages/finance/saldo-awal-ap/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Saldo Awal Ap", to: "/saldo-awal-ap" }, { label: "Create" }],
            role: "ROLE_SALDOAWALAP",
        },
    },
    {
        path: `/saldo-awal-ar`,
        name: "saldo-awal-ar.index",
        component: () => import("../pages/finance/saldo-awal-ar/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Saldo Awal Ar", to: "/saldo-awal-ar" }, { label: "Create" }],
            role: "ROLE_SALDOAWALAR",
        },
    },

    // Kas
    {
        path: `/kas/masuk`,
        name: "kasmasuk.index",
        component: () => import("../pages/finance/kas/masuk/list.vue"),
        meta: {
            breadcrumb: [
                { parent: "Finance", label: "Kas Masuk", to: "/kas/masuk" },
                // { label: "Create" }
            ],
            role: "ROLE_KASMASUK",
        },
    },
    {
        path: `/kas/masuk/create`,
        name: "kasmasuk.create",
        component: () => import("../pages/finance/kas/masuk/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Kas Masuk", to: "/kas/masuk" }, { label: "Create" }],
            role: "ROLE_KASMASUK",
        },
    },
    {
        path: `/kas/masuk/:id/edit`,
        name: "kasmasuk.edit",
        component: () => import("../pages/finance/kas/masuk/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Kas Masuk", to: "/kas/masuk" }, { label: "Edit" }],
            role: "ROLE_KASMASUK",
        },
    },
    {
        path: `/kas/keluar`,
        name: "kaskeluar.index",
        component: () => import("../pages/finance/kas/keluar/list.vue"),
        meta: {
            breadcrumb: [
                { parent: "Finance", label: "Kas Keluar", to: "/kas/keluar" },
                // { label: "Create" }
            ],
            role: "ROLE_KASKELUAR",
        },
    },
    {
        path: `/kas/keluar/create`,
        name: "kaskeluar.create",
        component: () => import("../pages/finance/kas/keluar/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Kas Keluar", to: "/kas/keluar" }, { label: "Create" }],
            role: "ROLE_KASKELUAR",
        },
    },
    {
        path: `/kas/keluar/:id/edit`,
        name: "kaskeluar.edit",
        component: () => import("../pages/finance/kas/keluar/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Kas Keluar", to: "/kas/keluar" }, { label: "Edit" }],
            role: "ROLE_KASKELUAR",
        },
    },
    {
        //uang muka pembelian
        path: `/uang-muka-pembelian`,
        name: "uang-muka-pembelian.index",
        component: () => import("../pages/finance/uang-muka-pembelian/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Uang Muka Pembayaran", to: "/uang-muka-pembelian" }],
            role: 'ROLE_UANGMUKAPEMBELIAN',
        },
    },
    {
        //uang muka pembelian
        path: `/uang-muka-pembelian/:id/jurnal`,
        name: "uang-muka-pembelian.jurnal",
        component: () => import("../pages/finance/uang-muka-pembelian/jurnal.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Uang Muka Pembayaran", to: "/uang-muka-pembelian" }, { label: "Jurnal" }],
            role: 'ROLE_UANGMUKAPEMBELIAN',
        },
    },
    {
        //Posting Account Bulanan
        path: `/account-posting`,
        name: "account-posting.index",
        component: () => import("../pages/finance/account-posting/form.vue"),
        meta: {
            breadcrumb: [{ parent: "Finance", label: "Account", to: "/account-posting" }],
            role: 'ROLE_ACCOUNTPOSTING',
        },
    },
];
export default routes;
