const routes = [
    {
        path: `/setting/saldo-awal`,
        name: "saldoawal.index",
        component: () => import("../pages/setting/saldo-awal/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Setting", label: "Saldo Awal" }],
            role: "ROLE_SALDOAWAL",
        },
    },
    {
        path: `/setting/stok-awal-barang`,
        name: "stokawalbarang.index",
        component: () => import("../pages/setting/stok-awal-barang/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Setting", label: "Stok Awal Barang" }],
            role: "ROLE_STOKAWALBARANG",
        },
    },
    {
        path: `/setting/klasifikasi-akun`,
        name: "klasifikasiakun.index",
        component: () => import("../pages/setting/klasifikasi-akun/list.vue"),
        meta: {
            breadcrumb: [{ parent: "Setting", label: "Klasifikasi Akun" }],
            role: "ROLE_KLASIFIKASIAKUN",
        },
    },
];
export default routes;
