import { inject } from "vue";
import apiSetting from "../service/api/setting/setting";

export const settingVar = {};

await apiSetting.index().then((res) => {
    for (let i = 0; i < res.data.data.length; i++) {
        const settingData = res.data.data[i];
        switch (settingData.type) {
            case "integer":
                settingVar[settingData.name] = parseInt(settingData.value);
                break;

            case "float":
                settingVar[settingData.name] = parseFloat(settingData.value);
                break;

            case "boolean":
                settingVar[settingData.name] = settingData.value == "true";
                break;

            case "date":
                settingVar[settingData.name] = new Date(settingData.value);
                break;

            default:
                settingVar[settingData.name] = settingData.value;
                break;
        }
    }
});

// Plugin Settings
const Settings = {
    install: function (app, options) {
        options = options || {};
        options.requestOnNotify = options.requestOnNotify || true;

        app.config.globalProperties.$setting = settingVar;
        app.provide("setting", app.config.globalProperties.$setting);
    },
};

export function useSettings() {
    return inject("setting");
}

export default Settings;

// formatDate(date) {
//     moment.locale('id');
//     return moment(date).format('DD MMMM YYYY');
// },
