<template>
    <label>
        {{ value }} <slot></slot> {{ " " }}
        <small class="text-red-500 font-bold" v-if="required">*</small>
        <small class="text-purple-500 font-bold" v-if="generated">*</small>
    </label>
</template>

<script>
export default {
    name: "Label",
    props: {
        value: null,
        required: Boolean,
        generated: Boolean,
        labelClass: String,
    },
};
</script>

<style lang="scss" scoped></style>
