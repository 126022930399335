const routes = [
    // START Erza

    //END Erza

    // START Alwi
    {
        path: "/report/stok-barang",
        name: "stok-barang.report",
        component: () => import("../pages/report/stokbarang/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Stok Barang", to: "#" },
            ],
            role: "ROLE_STOKBARANG",
        },
    },
    {
        path: "/report/laba-rugi",
        name: "laba-rugi.report",
        component: () => import("../pages/report/laba-rugi/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Stok Barang", to: "#" },
            ],
            role: "ROLE_STOKBARANG",
        },
    },
    {
        path: "/report/bulanan",
        name: "bulanan.report",
        component: () => import("../pages/report/persediaan/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Persediaan", to: "#" },
            ],
            role: "ROLE_PERSEDIAANBULANAN",
        },
    },
    {
        path: "/report/realisasi-work-order",
        name: "realisasi-wo.report",
        component: () => import("../pages/report/workorder/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Stok Barang", to: "#" },
            ],
            role: "ROLE_REALISASIWO",
        },
    },
    {
        path: "/report/history-stok",
        name: "history-stok.report",
        component: () => import("../pages/report/historystok/form.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Stok Barang", to: "#" },
            ],
        },
        role: "ROLE_HISTORYSTOK",
    },
    {
        path: "/report/bulanan",
        name: "bulanan.report",
        component: () => import("../pages/report/persediaan/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Persediaan", to: "#" },
            ],
            role: "ROLE_PERSEDIAANBULANAN",
        },
    },
    {
        path: "/report/buku-besar",
        name: "buku-besar.report",
        component: () => import("../pages/report/buku-besar/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Buku Besar", to: "#" },
            ],
            role: "ROLE_BUKUBESAR",
        },
    },
    //END Alwi
    {
        path: "/report/hutang",
        name: "hutang.report",
        component: () => import("../pages/report/hutang/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Laporan Ap", to: "#" },
            ],
            role: 'ROLE_LAPORANHUTANG'
        },
    },
    {
        path: "/report/piutang",
        name: "piutang.report",
        component: () => import("../pages/report/piutang/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Laporan Ar", to: "#" },
            ],
            role: 'ROLE_LAPORANPIUTANG'
        },
    },
    {
        path: "/report/umur-invoice-ap",
        name: "umurinvoiceap.report",
        component: () => import("../pages/report/umur-invoice/ap/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Umur Invoice Ap", to: "#" },
            ],
            role: 'ROLE_UMURINVOICEAP'
        },
    },
    {
        path: "/report/umur-invoice-ar",
        name: "umurinvoicear.report",
        component: () => import("../pages/report/umur-invoice/ar/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Umur Invoice Ar", to: "#" },
            ],
            role: 'ROLE_UMURINVOICEAR'
        },
    },
    {
        path: "/report/outstanding-po",
        name: "outstandingpo.report",
        component: () => import("../pages/report/outstanding-po/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Outstanding Po", to: "#" },
            ],
            role: 'ROLE_OUTSTANDINGPO'
        },
    },
    {
        path: "/report/outstanding-pp",
        name: "outstandingpp.report",
        component: () => import("../pages/report/outstanding-pp/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Outstanding Permintaan penawaran", to: "#" },
            ],
            // role: 'ROLE_OUTSTANDINGPP'
        },
    },
    {
        path: "/report/outstanding-so",
        name: "outstandingso.report",
        component: () => import("../pages/report/outstanding-so/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Outstanding Sales Order", to: "#" },
            ],
            role: 'ROLE_OUTSTANDINGSO'
        },
    },
    {
        path: "/report/monitoring-sales-order",
        name: "monitoring-sales-order.report",
        component: () => import("../pages/report/monitoring/sales-order/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Monitoring", to: "#" },
                { label: "Sales Order", to: "#" },
            ],
            role: 'ROLE_MONITORINGSALESORDER'
        },
    },
    {
        path: "/report/jurnal",
        name: "jurnal.report",
        component: () => import("../pages/report/jurnal/list.vue"),
        meta: {
            breadcrumb: [
                { label: "Report", to: "#" },
                { label: "Jurnal", to: "#" },
            ],
            role: 'ROLE_REPORTJURNAL'
        },
    },
];
export default routes;
