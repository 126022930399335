<template>
    <Skeleton v-if="$isLoading.value" width="100%" height="720px"></Skeleton>
    <div v-else class="print-container">
        <div class="print-content">
            <div class="page-info">
                <div class="page-count">
                    <span v-if="showAllPages"> {{ totalPages }} Halaman </span>
                    <span v-else>
                        Halaman
                        <button :disabled="currentPage <= 1" @click="currentPage--">❮</button>

                        {{ currentPage }} / {{ totalPages }}

                        <button :disabled="currentPage >= totalPages" @click="currentPage++">❯</button>
                    </span>
                    <label class="right">
                        <input v-model="showAllPages" type="checkbox" />
                        Show all pages
                    </label>
                    <div class="actions">
                        <button @click="downloadPdf">Download</button>
                        <button @click="printPage">Print</button>
                    </div>
                </div>
            </div>

            <!-- Tombol aksi -->

            <!-- Konten utama -->
            <div class="main-content">
                <vue-pdf-embed ref="pdfRef" :source="source" :page="currentPage" @password-requested="handlePasswordRequest" @rendered="handleDocumentRender" />
                <!-- Isi konten -->
            </div>
        </div>

        <div class="print-sidebar">
            <!-- Sidebar dengan preview halaman -->
            <div v-for="page in totalPages" :key="page" class="page-preview">
                <vue-pdf-embed :source="source" :page="page"/>
            </div>
        </div>
    </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
export default {
    data() {
        return {
            currentPage: 1,
            totalPages: 0,
            showAllPages: false,
        };
    },
    props: {
        source: {
            type: Uint8Array,
            default: null,
        },
        filename: {
            type: String,
            default: null,
        },
    },
    components: {
        VuePdfEmbed,
    },
    methods: {
        handleDocumentRender() {
            this.totalPages = this.$refs.pdfRef.pageCount;
        },
        handlePasswordRequest(callback, retry) {
            callback(prompt(retry ? "Enter password again" : "Enter password"));
        },
        printPage() {
            this.$refs.pdfRef.print();
        },
        downloadPdf() {
            this.$refs.pdfRef.download(this.filename);
        },
    },
    watch: {
        showAllPages() {
            this.currentPage = this.showAllPages ? null : 1;
        },
    },
};
</script>

<style scoped>
.print-container {
    display: flex;
}

.print-content {
    width: 85%;
    padding: 20px;
    background-color: #f4f4f4;
}

.page-info {
    justify-content: space-between;
    background-color: #333;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
    text-align: center;
    margin-bottom: 20px;
    height: 60px;
}

.page-status {
    margin-top: 10px;
}

.page-count {
    padding: 20px;
    color: white;
}

.main-content {
    overflow-y: scroll;
}

.page-count .actions {
    text-align: right;
    float: right;
}

button {
    margin-left: 10px;
}

.print-sidebar {
    overflow-y: scroll;
    width: 15%;
    padding: 20px;
    background-color: #333;
}

.page-preview {
    margin-bottom: 10px;
}

/* Media query untuk perangkat seluler dengan lebar maksimal 600px */
@media only screen and (max-width: 600px) {
    .print-container {
        display: flex;
        flex-direction: column; /* Mengubah tata letak ke vertikal pada perangkat seluler */
    }
    .print-sidebar {
        display: none; /* Sembunyikan sidebar di perangkat seluler */
    }

    .print-content {
        width: 100%; /* Lebar 100% untuk perangkat seluler */
    }

    .page-count {
        flex-direction: column; /* Mengubah orientasi menjadi vertikal pada perangkat seluler */
        text-align: center;
        margin-top: 10px;
    }

    .page-count .actions {
        text-align: center;
    }

    .page-count .right {
        display: none;
    }

    button {
        margin-left: 0;
        margin-top: 5px;
    }
}
</style>
