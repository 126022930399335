<template>
    <small class="p-invalid text-red-500" v-if="error">{{ error._errors[0] }}</small>
</template>

<script>
export default {
    name: "ErrorMsg",
    props: {
        error: Object,
    },
};
</script>

<style lang="scss" scoped></style>
